import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import util from 'src/util/util';
import { withAppContext } from '../../store/initAppContext'
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
// import {color} from '../../../sass-files/variable';
// import searchicon from '../../images/search_icon.png';
import LiveTvIcon from "src/images/live_tv.png";
import Intl from '../common/Intl';
import { palette, theme } from 'styled-tools';


// const {secondarycolor} = color

class InPlayLiveTv extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.triggerLiveTv = this.triggerLiveTv.bind(this)
    }


    triggerLiveTv = () => {
        const { setState } = this.props.app || {};
        if (typeof setState === 'function') {
            let _reqData = {
                showLiveTv: !this.props.app.showLiveTv
            }
            setState(_reqData)
        }
    }
    render() {
        const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
        const avlCredit = Number(cookieUtil.get(ECookieName.COOKIE_BALANCE)) || 0;
        const exposure = Number(cookieUtil.get(ECookieName.COOKIE_EXPOSURE)) || 0;
        const showLiveTv = ( avlCredit + Math.abs(exposure) ) >= 1200 ? true : false
        
       
        return (
            <LinksBlock> 
                <Link to="/" className="tab-name"><Intl langKey="TITLE@INPLAY" /></Link>
               {/* {isAuth && showLiveTv&&
                    <a href={"https://livingstreamvdo.com" + "?aHR0cHM6Ly9iYmIuZ2FtZXM="} target="_blank" className="live-tv">
                        <LiveTvHomePage>
                            <img src={LiveTvIcon} alt="" />
                            <Intl langKey="HEADER@MULTITV" /> 
                        </LiveTvHomePage>
                    </a>
                    }  */}
            </LinksBlock>
        )
    }
}

export default withAppContext(InPlayLiveTv);

const LiveTvHomePage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${theme('palette.exchLiveTvColor')};
    font-weight: 500;
    font-size: 15px;


    :before {
        content: "";
        width: 1px;
        height: 20px;
        background: ${theme('palette.exchLiveTvLeftLine')};
        display: inline-block;
        margin: 0px 20px;
    }

    img {
        /* padding-right: 5px; */
    }
`;
const LinksBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .tab-name {
        font-size: 15px;
        color: ${theme('palette.InPlayHeaderTittle')};
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        align-items: center;
    }

    .live-tv {
        font-size: 15px;
        // color: #C18523;     //changes needed here
        // color: ${palette('palette.livetvColor')};
        color:${theme('palette.livetvColor')};
        
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
    }

    .italics {
        margin: 0;
        padding: 0;
        color: #E64C3C;
        letter-spacing: 0.07em;
        text-decoration-line: underline;
        text-transform: capitalize;
        font-family: 'poppinsitalic';
        font-size: 12px;
        padding-left: 20px;
    }
`;