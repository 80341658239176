import React from 'react'
import util from '../../../../util/util';
import styled from 'styled-components';
import { withAppContext } from '../../../../store/initAppContext';
import {motion} from 'framer-motion'
import CloseIcon from '../../../../images/cancel_outline_white.png';
import config from 'src/config/config';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';

function LiveTv(props) {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-0vw",
            scale: 0.8
        },
        in: {
            opacity: 1,
            x: 0,
            scale: 1
        },
        out: {
            opacity: 0,
            x: "0vw",
            scale: 1.2
        }
    }

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.5
    }
    const { liveTvUrl } = props.app
    const closeLiveTv = () => {
        const { setState } = props.app || {};
        if (typeof setState === 'function') {
            let _reqData = {
                showLiveTv: false
            }
            setState(_reqData)
        }
    }


    const avlCredit = Number(cookieUtil.get(ECookieName.COOKIE_BALANCE)) || 0;
    const exposure = Number(cookieUtil.get(ECookieName.COOKIE_EXPOSURE)) || 0;
    const showLiveTv = (avlCredit + Math.abs(exposure)) >= 1200 ? true : false

    return (
        <>
             {/* {showLiveTv ?
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <LiveTvCol>
                        <span onClick={closeLiveTv}></span>
                        <LiveTvWrapper>
                            <TVIFrame
                                //https://livingstreamvdo.com
                                src={`https://new.livingstreamvdo.com?eventId=` + Number(liveTvUrl)}
                                width={'100%'}
                                height={'100%'}
                                scrolling={'no'}
                            />
                        </LiveTvWrapper>
                    </LiveTvCol>
                </motion.div>
                : <></>} */}
        </>
    )
}

export default withAppContext(LiveTv)


const LiveTvCol = styled.div`
    position: relative;
    z-index: 99999;
    width: 100%;
    text-align: right;
    cursor: pointer;
    // margin-top: 5px;
    span {
        // position: absolute;
        // right: 0;
        // top: -19px;
        background-repeat:no-repeat;
        background-size:30px;
        background-image: url(${CloseIcon});
        padding-top:0px;
        /* background-color: #dbb984; */
        font-size: 14px;
        color: #443939;
        width: 30px;
        height: 30px;
        position: absolute;
        background-position: center;
        right: 15px;
        z-index: 9999999;
        top: 25px;
    }
`;

const LiveTvWrapper = styled.div`
//   display:flex;
//   justify-content: center;
//   align-items: center;
//   background:#E5E5E5;
//   background: linear-gradient(180.01deg, #E5E5E5 0%, #FFFFFF 100%);
//   height: 300px;
position: relative;
width: 100%;
overflow: hidden;
padding-top: 56.25%; /* 16:9 Aspect Ratio */
background-color: #000000;
background-image: url(${config.imageV3NonOptHost}/static/cashsite/brand/6/image/liveTvLoaderNew.gif);
display: flex;
justify-content: center;
align-items: center;
background-repeat: no-repeat;
background-position: center;
`;

const TVIFrame = styled.iframe`
	// transform: translateY(59px);
	// background: #fff;
	// border: none;
	// border-bottom: 2px solid black;
	// z-index: 99999;
    // position: relative;
    // bottom: 50px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const Close = styled.div`
    background-image: url(${CloseIcon})
`;
